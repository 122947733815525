import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import NuclearEnergyAct from './views/nuclear-energy-act'
import Coal2Nuclear from './views/coal2nuclear'
import Home from './views/home'
import Misconception from './views/misconception'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={NuclearEnergyAct} exact path="/nuclear-energy-act" />
        <Route component={Coal2Nuclear} exact path="/coal2nuclear" />
        <Route component={Home} exact path="/" />
        <Route component={Misconception} exact path="/misconception" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
