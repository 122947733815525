import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './nuclear-energy-act.css'

const NuclearEnergyAct = (props) => {
  return (
    <div className="nuclear-energy-act-container">
      <Helmet>
        <title>NuclearEnergyAct - Government Project Nuclear Energy</title>
        <meta
          property="og:title"
          content="NuclearEnergyAct - Government Project Nuclear Energy"
        />
      </Helmet>
      <div data-role="Header" className="nuclear-energy-act-navbar-container">
        <div className="nuclear-energy-act-navbar">
          <div className="nuclear-energy-act-logo">
            <img
              alt="image"
              src="https://i.imgur.com/47T9K2s.png"
              className="nuclear-energy-act-image"
            />
          </div>
          <div className="nuclear-energy-act-links-container">
            <Link to="/" className="nuclear-energy-act-link Anchor">
              Home
            </Link>
            <Link
              to="/coal2nuclear"
              className="nuclear-energy-act-link01 Anchor"
            >
              Coal2Nuclear
            </Link>
            <Link
              to="/misconception"
              className="nuclear-energy-act-link02 Anchor"
            >
              Common Misconseptions
            </Link>
            <Link
              to="/nuclear-energy-act"
              className="nuclear-energy-act-link03 Anchor"
            >
              Nuclear Energy Act
            </Link>
          </div>
          <div className="nuclear-energy-act-cta-container">
            <div
              data-role="BurgerMenu"
              className="nuclear-energy-act-burger-menu"
            >
              <svg viewBox="0 0 1024 1024" className="nuclear-energy-act-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
          <div
            data-role="MobileMenu"
            className="nuclear-energy-act-mobile-menu"
          >
            <div className="nuclear-energy-act-top">
              <img
                alt="image"
                src="/playground_assets/default-img.svg"
                className="nuclear-energy-act-image1"
              />
              <div
                data-role="CloseMobileMenu"
                className="nuclear-energy-act-container1"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="nuclear-energy-act-icon02"
                >
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="nuclear-energy-act-mid">
              <div className="nuclear-energy-act-links-container1">
                <a
                  href="#features"
                  className="nuclear-energy-act-link04 Anchor"
                >
                  features
                </a>
                <a
                  href="#services"
                  className="nuclear-energy-act-link05 Anchor"
                >
                  services
                </a>
                <span className="nuclear-energy-act-link06 Anchor">
                  About Us
                </span>
                <a href="#contact" className="nuclear-energy-act-link07 Anchor">
                  contact
                </a>
              </div>
              <button className="nuclear-energy-act-cta-btn button Anchor">
                sTART BUILDING
              </button>
            </div>
            <div className="nuclear-energy-act-bot">
              <div className="nuclear-energy-act-social-links-container">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="nuclear-energy-act-icon04"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="nuclear-energy-act-icon06"
                >
                  <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
                </svg>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="nuclear-energy-act-icon08"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nuclear-energy-act-features">
        <div className="nuclear-energy-act-heading-container">
          <h2 className="nuclear-energy-act-text">
            <span className="Section-Heading">
              The International Nuclear Energy Act of 2022
            </span>
            <br></br>
            <br></br>
          </h2>
        </div>
        <div className="nuclear-energy-act-cards-container">
          <div className="nuclear-energy-act-feature-card">
            <span className="nuclear-energy-act-heading Card-Heading">
              What is the act?
            </span>
            <span className="nuclear-energy-act-text04">
              <span>
                According to Joe Machin, the Chairman of the Senate Energy and
                Natural Resource Committee, who was responsible for introducing
                the act, the International Nuclear Energy Act of 2022 is
                legislation that &quot;will promote the safe, secure, and
                peaceful use of civil nuclear energy&quot;
              </span>
              <br></br>
            </span>
          </div>
          <div className="nuclear-energy-act-feature-card1">
            <span className="nuclear-energy-act-heading1">
              <span className="Card-Heading">Why?</span>
              <br></br>
            </span>
            <span className="nuclear-energy-act-text09">
              <a
                href="https://www.energy.senate.gov/2022/4/manchin-risch-introduce-the-international-nuclear-energy-act-of-2022#:~:text=%E2%80%9CWe%20must%20not,said%20Chairman%20Manchin."
                target="_blank"
                rel="noreferrer noopener"
                className="nuclear-energy-act-link08"
              >
                According to Chairman Manchin
              </a>
              <span className="nuclear-energy-act-text10">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="nuclear-energy-act-text11"></br>
              <span className="nuclear-energy-act-text12">
                &gt; &quot;We must not turn a blind eye as China and Russia
                aggressively use their state-owned and operated civil nuclear
                programs as coercive tools to the detriment of other
                nations&apos; energy security and our global nonproliferation
                efforts. The United States must be proactive in confronting
                these geopolitical threats, not only for our national security
                but also for the entire free world&quot;
              </span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="nuclear-energy-act-features1">
        <div className="nuclear-energy-act-heading-container1">
          <h2 className="nuclear-energy-act-text14">
            <span>What does the legislation entail?</span>
            <br></br>
            <a
              href="https://www.energy.senate.gov/2022/4/manchin-risch-introduce-the-international-nuclear-energy-act-of-2022"
              target="_blank"
              rel="noreferrer noopener"
              className="nuclear-energy-act-link09"
            >
              Senate&apos;s page on the act
            </a>
            <br></br>
          </h2>
        </div>
        <div className="nuclear-energy-act-cards-container1">
          <div className="nuclear-energy-act-feature-card2">
            <span className="nuclear-energy-act-heading2">
              Create an Office
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="nuclear-energy-act-text18">
              <span className="nuclear-energy-act-text19">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="nuclear-energy-act-text20">
                to coordinate civil nuclear exports strategy; establish
                financing relationships; promote regulatory harmonization;
                enhance safeguards and security; promote standardization of
                licensing framework; and create an exports working group. 
              </span>
              <br className="nuclear-energy-act-text21"></br>
            </span>
          </div>
          <div className="nuclear-energy-act-feature-card3">
            <span className="nuclear-energy-act-heading3">
              <span className="Card-Heading">Create Programs</span>
              <br></br>
            </span>
            <span className="nuclear-energy-act-text24">
              <span className="nuclear-energy-act-text25">
                to facilitate international nuclear energy cooperation to
                develop financing relationships, training, education, market
                analysis, safety, security, safeguards and nuclear governance
                required for a civil nuclear program.
              </span>
              <br></br>
            </span>
          </div>
          <div className="nuclear-energy-act-feature-card4">
            <span className="nuclear-energy-act-heading4">
              <span className="Card-Heading">Create a schedule of</span>
              <br></br>
            </span>
            <span className="nuclear-energy-act-text29">
              <span className="nuclear-energy-act-text30">
                two biennial summits, one focused on nuclear safety, security
                and safeguards, and another for civil nuclear vendors to enhance
                cooperative relationships between private industry and
                government.
              </span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="nuclear-energy-act-features2">
        <div className="nuclear-energy-act-cards-container2">
          <div className="nuclear-energy-act-feature-card5">
            <span className="nuclear-energy-act-heading5">
              Create the Stratigic Infrastructure Fund Working Group
            </span>
            <span className="nuclear-energy-act-text32">
              <span className="nuclear-energy-act-text33">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="nuclear-energy-act-text34">
                to determine how to best structure a Fund to finance projects
                critical to national security.
              </span>
              <br className="nuclear-energy-act-text35"></br>
            </span>
          </div>
          <div className="nuclear-energy-act-feature-card6">
            <span className="nuclear-energy-act-heading6">
              <span className="Card-Heading">Create fast-track Procedures</span>
              <br></br>
            </span>
            <span className="nuclear-energy-act-text38">
              <span className="nuclear-energy-act-text39">
                for deemed civil nuclear exports for countries defined by the
                Secretary of Energy.
              </span>
              <br></br>
            </span>
          </div>
          <div className="nuclear-energy-act-feature-card7">
            <span className="nuclear-energy-act-heading7">
              <span className="Card-Heading">
                Expand the Export-Import Bank Program
              </span>
              <br></br>
            </span>
            <span className="nuclear-energy-act-text43">
              <span className="nuclear-energy-act-text44">
                two biennial summits, one focused on nuclear safety, security
                and safeguards, and another for civil nuclear vendors to enhance
                cooperative relationships between private industry and
                government.
              </span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="nuclear-energy-act-contact">
        <div className="nuclear-energy-act-content-container">
          <h2 className="nuclear-energy-act-text46 Section-Heading">
            A High Schoolers Government Final Project
          </h2>
          <div className="nuclear-energy-act-locations-container">
            <div className="nuclear-energy-act-location-1">
              <span className="nuclear-energy-act-heading8">Contact Info</span>
              <div className="nuclear-energy-act-email">
                <svg
                  viewBox="0 0 1024 1024"
                  className="nuclear-energy-act-icon10"
                >
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
                <span className="Section-Text">zigzatuzoo@zigzatuzoo.xyz</span>
              </div>
              <div className="nuclear-energy-act-phone">
                <svg
                  viewBox="0 0 804.5714285714286 1024"
                  className="nuclear-energy-act-icon12"
                >
                  <path d="M804.571 708.571c0 20.571-9.143 60.571-17.714 79.429-12 28-44 46.286-69.714 60.571-33.714 18.286-68 29.143-106.286 29.143-53.143 0-101.143-21.714-149.714-39.429-34.857-12.571-68.571-28-100-47.429-97.143-60-214.286-177.143-274.286-274.286-19.429-31.429-34.857-65.143-47.429-100-17.714-48.571-39.429-96.571-39.429-149.714 0-38.286 10.857-72.571 29.143-106.286 14.286-25.714 32.571-57.714 60.571-69.714 18.857-8.571 58.857-17.714 79.429-17.714 4 0 8 0 12 1.714 12 4 24.571 32 30.286 43.429 18.286 32.571 36 65.714 54.857 97.714 9.143 14.857 26.286 33.143 26.286 50.857 0 34.857-103.429 85.714-103.429 116.571 0 15.429 14.286 35.429 22.286 49.143 57.714 104 129.714 176 233.714 233.714 13.714 8 33.714 22.286 49.143 22.286 30.857 0 81.714-103.429 116.571-103.429 17.714 0 36 17.143 50.857 26.286 32 18.857 65.143 36.571 97.714 54.857 11.429 5.714 39.429 18.286 43.429 30.286 1.714 4 1.714 8 1.714 12z"></path>
                </svg>
                <span>(765) 357-4688‬ (Vo-IP number)</span>
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://github.com/zigzatuzoo"
          target="_blank"
          rel="noreferrer noopener"
          className="nuclear-energy-act-link10"
        >
          <img
            alt="image"
            src="https://i.imgur.com/ycne9nW.png"
            loading="lazy"
            className="nuclear-energy-act-image2"
          />
        </a>
      </div>
    </div>
  )
}

export default NuclearEnergyAct
